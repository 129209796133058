.App {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

/* img {
  width: 100%;
} */

#spinwheel-page, #reward-page {
  background-image: url('./assets/iPhone 14 & 15 Pro Max - 30.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: fit-content; */
  padding-bottom: 30px;
  text-align: center;
}

.hotline-btn {
  width: 64px;
  height: 64px;
  background: #386CAF;
  border-radius: 50%;
  box-shadow: 2px 2px 8px 0px #0000002E;
  position: absolute;
  right: 5%;
  display: grid;
  place-items: center;
  top: 10%;
}

.gold-btn {
  background: linear-gradient(90deg, #CEA54E 0%, #E8D293 15%, #F1E0A8 19%, #E0C47D 31%, #C2912E 52%, #E5CE89 71%, #FFFACB 84%, #D9B76B 100%);
  color: white;
  font-weight: 700;
  width: 65%;
  height: 45px;
  font-size: 24px;
  margin-top: 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.canvasContainer {
  position: relative;
}

#canvas {
margin: 0 auto !important;
}

.content-container {
  position: relative;
  transform: translateY(50%);
  width: 60%;
  padding: 25px 15px;
  background: #fff7e5;
  color: #005496;
  border-radius: 16px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  font-weight: 900;
}

.form-input {
border: 1px solid #C2912E;
border-radius: 20px;
background: #fff1ce !important;
padding: 10px 16px;
color: #827c73 !important;
}

.gift-img-container {
  background-color: white;
  border-radius: 50%;
  height: 148px;
  width: 172px;
  position: absolute;
  top: -20%;
  z-index: 0;
  clip-path: ellipse(100% 50% at 50% 0%);
  left: 25%;
}